import React from 'react';
import "./App.css";

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo">
            <a href=".">VOPY.AI</a>
          </div>
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>Get ready for the change.</h1>
            <p>Website coming soon. Please check back to know more. Shoot us an email if you're curious.</p>
          </div>
          <a href="harshada.kushe@atcerp.com">
            <div className="cta">Send us an email</div>
          </a>
        </div>
        <div className="footer">
          <span> VOPY.AI Ltd. All Rights Reserved. </span>
        </div>
        <div className="subfooter">
          <span> 124, City Road, London, EC1V 2NX, UNITED KINGDOM
Registered in UNITED KINGDOM, Number 14481634  </span>
        </div>
      </div>
    );
  }
}

export default App;